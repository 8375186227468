import { getUserAgent } from "universal-user-agent";
import { request } from "@octokit/request";
import { auth } from "./auth";
import { hook } from "./hook";
import { VERSION } from "./version";
export { createOAuthUserAuth } from "@octokit/auth-oauth-user";
export function createOAuthAppAuth(options) {
    const state = Object.assign({
        request: request.defaults({
            headers: {
                "user-agent": `octokit-auth-oauth-app.js/${VERSION} ${getUserAgent()}`,
            },
        }),
        clientType: "oauth-app",
    }, options);
    // @ts-expect-error not worth the extra code to appease TS
    return Object.assign(auth.bind(null, state), {
        hook: hook.bind(null, state),
    });
}
