var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { tileProps } from './shared/propTypes';
function datesAreDifferent(date1, date2) {
    return ((date1 && !date2) ||
        (!date1 && date2) ||
        (date1 && date2 && date1.getTime() !== date2.getTime()));
}
var Tile = /** @class */ (function (_super) {
    __extends(Tile, _super);
    function Tile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {};
        return _this;
    }
    Tile.getDerivedStateFromProps = function (nextProps, prevState) {
        var activeStartDate = nextProps.activeStartDate, date = nextProps.date, tileClassName = nextProps.tileClassName, tileContent = nextProps.tileContent, view = nextProps.view;
        var nextState = {};
        var args = { activeStartDate: activeStartDate, date: date, view: view };
        if (tileClassName !== prevState.tileClassNameProps ||
            datesAreDifferent(activeStartDate, prevState.activeStartDateProps)) {
            nextState.tileClassName =
                typeof tileClassName === 'function' ? tileClassName(args) : tileClassName;
            nextState.tileClassNameProps = tileClassName;
        }
        if (tileContent !== prevState.tileContentProps ||
            datesAreDifferent(activeStartDate, prevState.activeStartDateProps)) {
            nextState.tileContent = typeof tileContent === 'function' ? tileContent(args) : tileContent;
            nextState.tileContentProps = tileContent;
        }
        nextState.activeStartDateProps = activeStartDate;
        return nextState;
    };
    Tile.prototype.render = function () {
        var _a = this.props, activeStartDate = _a.activeStartDate, children = _a.children, classes = _a.classes, date = _a.date, formatAbbr = _a.formatAbbr, locale = _a.locale, maxDate = _a.maxDate, maxDateTransform = _a.maxDateTransform, minDate = _a.minDate, minDateTransform = _a.minDateTransform, onClick = _a.onClick, onMouseOver = _a.onMouseOver, style = _a.style, tileDisabled = _a.tileDisabled, view = _a.view;
        var _b = this.state, tileClassName = _b.tileClassName, tileContent = _b.tileContent;
        return (React.createElement("button", { className: clsx(classes, tileClassName), disabled: (minDate && minDateTransform(minDate) > date) ||
                (maxDate && maxDateTransform(maxDate) < date) ||
                (tileDisabled && tileDisabled({ activeStartDate: activeStartDate, date: date, view: view })), onClick: onClick ? function (event) { return onClick(date, event); } : undefined, onFocus: onMouseOver ? function () { return onMouseOver(date); } : undefined, onMouseOver: onMouseOver ? function () { return onMouseOver(date); } : undefined, style: style, type: "button" },
            formatAbbr ? React.createElement("abbr", { "aria-label": formatAbbr(locale, date) }, children) : children,
            tileContent));
    };
    Tile.propTypes = __assign(__assign({}, tileProps), { children: PropTypes.node.isRequired, formatAbbr: PropTypes.func, maxDateTransform: PropTypes.func.isRequired, minDateTransform: PropTypes.func.isRequired });
    return Tile;
}(Component));
export default Tile;
