import { createOAuthAppAuth } from "@octokit/auth-oauth-app";
import { VERSION } from "./version";
import { addEventHandler } from "./add-event-handler";
import { OAuthAppOctokit } from "./oauth-app-octokit";
import { getUserOctokitWithState, } from "./methods/get-user-octokit";
import { getWebFlowAuthorizationUrlWithState, } from "./methods/get-web-flow-authorization-url";
import { createTokenWithState, } from "./methods/create-token";
import { checkTokenWithState, } from "./methods/check-token";
import { resetTokenWithState, } from "./methods/reset-token";
import { refreshTokenWithState, } from "./methods/refresh-token";
import { scopeTokenWithState, } from "./methods/scope-token";
import { deleteTokenWithState, } from "./methods/delete-token";
import { deleteAuthorizationWithState, } from "./methods/delete-authorization";
// generic handlers
export { handleRequest } from "./middleware/handle-request";
export { createNodeMiddleware } from "./middleware/node/index";
export { createCloudflareHandler, createWebWorkerHandler, } from "./middleware/web-worker/index";
export { createAWSLambdaAPIGatewayV2Handler } from "./middleware/aws-lambda/api-gateway-v2";
export class OAuthApp {
    constructor(options) {
        const Octokit = options.Octokit || OAuthAppOctokit;
        this.type = (options.clientType ||
            "oauth-app");
        const octokit = new Octokit({
            authStrategy: createOAuthAppAuth,
            auth: {
                clientType: this.type,
                clientId: options.clientId,
                clientSecret: options.clientSecret,
            },
        });
        const state = {
            clientType: this.type,
            clientId: options.clientId,
            clientSecret: options.clientSecret,
            // @ts-expect-error defaultScopes not permitted for GitHub Apps
            defaultScopes: options.defaultScopes || [],
            allowSignup: options.allowSignup,
            baseUrl: options.baseUrl,
            log: options.log,
            Octokit,
            octokit,
            eventHandlers: {},
        };
        this.on = addEventHandler.bind(null, state);
        // @ts-expect-error TODO: figure this out
        this.octokit = octokit;
        this.getUserOctokit = getUserOctokitWithState.bind(null, state);
        this.getWebFlowAuthorizationUrl = getWebFlowAuthorizationUrlWithState.bind(null, state);
        this.createToken = createTokenWithState.bind(null, state);
        this.checkToken = checkTokenWithState.bind(null, state);
        this.resetToken = resetTokenWithState.bind(null, state);
        this.refreshToken = refreshTokenWithState.bind(null, state);
        this.scopeToken = scopeTokenWithState.bind(null, state);
        this.deleteToken = deleteTokenWithState.bind(null, state);
        this.deleteAuthorization = deleteAuthorizationWithState.bind(null, state);
    }
    static defaults(defaults) {
        const OAuthAppWithDefaults = class extends this {
            constructor(...args) {
                super({
                    ...defaults,
                    ...args[0],
                });
            }
        };
        return OAuthAppWithDefaults;
    }
}
OAuthApp.VERSION = VERSION;
