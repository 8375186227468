export function oauthAuthorizationUrl(options) {
    const clientType = options.clientType || "oauth-app";
    const baseUrl = options.baseUrl || "https://github.com";
    const result = {
        clientType,
        allowSignup: options.allowSignup === false ? false : true,
        clientId: options.clientId,
        login: options.login || null,
        redirectUrl: options.redirectUrl || null,
        state: options.state || Math.random().toString(36).substr(2),
        url: "",
    };
    if (clientType === "oauth-app") {
        const scopes = "scopes" in options ? options.scopes : [];
        result.scopes =
            typeof scopes === "string"
                ? scopes.split(/[,\s]+/).filter(Boolean)
                : scopes;
    }
    result.url = urlBuilderAuthorize(`${baseUrl}/login/oauth/authorize`, result);
    return result;
}
function urlBuilderAuthorize(base, options) {
    const map = {
        allowSignup: "allow_signup",
        clientId: "client_id",
        login: "login",
        redirectUrl: "redirect_uri",
        scopes: "scope",
        state: "state",
    };
    let url = base;
    Object.keys(map)
        // Filter out keys that are null and remove the url key
        .filter((k) => options[k] !== null)
        // Filter out empty scopes array
        .filter((k) => {
        if (k !== "scopes")
            return true;
        if (options.clientType === "github-app")
            return false;
        return !Array.isArray(options[k]) || options[k].length > 0;
    })
        // Map Array with the proper URL parameter names and change the value to a string using template strings
        // @ts-ignore
        .map((key) => [map[key], `${options[key]}`])
        // Finally, build the URL
        .forEach(([key, value], index) => {
        url += index === 0 ? `?` : "&";
        url += `${key}=${encodeURIComponent(value)}`;
    });
    return url;
}
