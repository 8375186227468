import PropTypes from 'prop-types';
import { CALENDAR_TYPES } from './const';
var calendarTypes = Object.values(CALENDAR_TYPES);
var allViews = ['century', 'decade', 'year', 'month'];
export var isCalendarType = PropTypes.oneOf(calendarTypes);
export var isClassName = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
]);
export function isMinDate(props, propName, componentName) {
    var _a = props, _b = propName, minDate = _a[_b];
    if (!minDate) {
        return null;
    }
    if (!(minDate instanceof Date)) {
        return new Error("Invalid prop `".concat(propName, "` of type `").concat(typeof minDate, "` supplied to `").concat(componentName, "`, expected instance of `Date`."));
    }
    var maxDate = props.maxDate;
    if (maxDate && minDate > maxDate) {
        return new Error("Invalid prop `".concat(propName, "` of type `").concat(typeof minDate, "` supplied to `").concat(componentName, "`, minDate cannot be larger than maxDate."));
    }
    return null;
}
export function isMaxDate(props, propName, componentName) {
    var _a = props, _b = propName, maxDate = _a[_b];
    if (!maxDate) {
        return null;
    }
    if (!(maxDate instanceof Date)) {
        return new Error("Invalid prop `".concat(propName, "` of type `").concat(typeof maxDate, "` supplied to `").concat(componentName, "`, expected instance of `Date`."));
    }
    var minDate = props.minDate;
    if (minDate && maxDate < minDate) {
        return new Error("Invalid prop `".concat(propName, "` of type `").concat(typeof maxDate, "` supplied to `").concat(componentName, "`, maxDate cannot be smaller than minDate."));
    }
    return null;
}
export var isRef = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
        current: PropTypes.any,
    }),
]);
export var isValue = PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.arrayOf(PropTypes.instanceOf(Date)),
]);
export var isViews = PropTypes.arrayOf(PropTypes.oneOf(allViews));
export function isView(props, propName, componentName) {
    var _a = props, _b = propName, view = _a[_b];
    var views = props.views;
    var allowedViews = views || allViews;
    if (view !== undefined && (typeof view !== 'string' || allowedViews.indexOf(view) === -1)) {
        return new Error("Invalid prop `".concat(propName, "` of value `").concat(view, "` supplied to `").concat(componentName, "`, expected one of [").concat(allowedViews
            .map(function (a) { return "\"".concat(a, "\""); })
            .join(', '), "]."));
    }
    // Everything is fine
    return null;
}
isView.isRequired = function (props, propName, componentName) {
    var _a = props, _b = propName, view = _a[_b];
    if (!view) {
        return new Error("The prop `".concat(propName, "` is marked as required in `").concat(componentName, "`, but its value is `").concat(view, "`."));
    }
    return isView(props, propName, componentName);
};
export var tileGroupProps = {
    activeStartDate: PropTypes.instanceOf(Date).isRequired,
    hover: PropTypes.instanceOf(Date),
    locale: PropTypes.string,
    maxDate: isMaxDate,
    minDate: isMinDate,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    tileClassName: PropTypes.oneOfType([PropTypes.func, isClassName]),
    tileContent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    value: isValue,
    valueType: PropTypes.string,
};
export var tileProps = {
    activeStartDate: PropTypes.instanceOf(Date).isRequired,
    classes: PropTypes.arrayOf(PropTypes.string).isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    locale: PropTypes.string,
    maxDate: isMaxDate,
    minDate: isMinDate,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    tileClassName: PropTypes.oneOfType([PropTypes.func, isClassName]),
    tileContent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    tileDisabled: PropTypes.func,
};
